import React, { useEffect, useState } from 'react'
import {
	LectureWrapper,
	LectureListWrapper,
	LectureView,
	LectureViewHeader,
	LectureViewTable,
	FixBtns,
	LectureListSearch,
} from './styles'
import { Icon, IsAdd, IsAddBtn } from '../Home/styles'
import { FaCheck } from 'react-icons/fa6'
import { BsFillPencilFill } from 'react-icons/bs'
import AddLectureModal from 'app/gomsacademy/components/AddLectureModal'
import LectureList from '../LectureContent/LectureList'
import TotalTable from '../LectureContent/TotalTable'
import { useLectureStudents } from 'app/gomsacademy/hooks/LectureStudents'
import { useTeacherStudent } from 'app/gomsacademy/hooks/TeacherStudentList'
import API from 'utils/api'
import { Select, message } from 'antd'
import { useLectureList } from 'app/gomsacademy/hooks/LectureList'
import { useToken } from 'app/hooks'
import { convertBinaryToDays, convertDaysToBinary } from 'utils/ciutils'
import { useLocation } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa6'
import { CiSearch } from 'react-icons/ci'
import {useDashboardLecture} from "../../hooks/dashboardLecture";
import {useDeleteLectureStudents} from "../../hooks/DeleteLectureStudents";

export const Lecture = ({
	isWrite,
	setIsWrite,
	setManageSt,
	setSelectStudentNote,
	setLectureSt,
	selectLecture,
	setSelectLecture,
	setEditNoteIndex,
}) => {
	const { data: token } = useToken()

	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const id = params.get('id')

	const { LectureStudentsData, mutate: SelectLectureMutate } = useLectureStudents(
		selectLecture?.id || id
	)


	const { LectureListData, mutate: lectureMutate } = useLectureList(0)
	const { TeacherStudentData } = useTeacherStudent(2)
	const { TeacherStudentData: StudentAll } = useTeacherStudent(3)

	const { DashboardLectureStudentsData, mutate:LectureStudentsDashboardDataMutate } = useDashboardLecture(selectLecture?.id);

	const [isTotal, setIsTotal] = useState(true)
	const [isAddLecture, setIsAddLecture] = useState(false)
	const [isFix, setIsFix] = useState(false)
	const [isAdd, setIsAdd] = useState(false)
	const [searchKeyword, setSearchKeyword] = useState('')
	const [AddStudent, setAddStudent] = useState<number[]>([])

	const [fixName, setFixName] = useState(selectLecture?.name || '')
	const firstTeacher = TeacherStudentData?.find(
		teacher => teacher.name == selectLecture?.teacherNames?.[0]
	)?.id
	const firstTeacherName = selectLecture?.teacherNames?.[0]
	const [fixTeacher, setFixTeacher] = useState(firstTeacher || '')
	const [fixType, setFixType] = useState<number>(1)
	const [fixMaxCount, setFixMaxCount] = useState(selectLecture?.maxCount || '')
	const [fixTime, setFixTime] = useState(selectLecture?.time || '')
	const [fixStart, setFixStart] = useState(selectLecture?.startedAt || '')
	const [fixEnd, setFixEnd] = useState(selectLecture?.endedAt || '')
	const [selectGrade, setSelectGrade] = useState('전체')

	const [selectedDays, setSelectedDays] = useState(selectLecture?.days)
	const textDays = convertBinaryToDays(selectedDays)


	useEffect(() => {
		console.log('fixTeacher', fixTeacher);
	}, [fixTeacher])

	useEffect(() => {
		console.log('TeacherStudentData', TeacherStudentData)
	}, [TeacherStudentData])


	useEffect(() => {
		console.log(StudentAll)
	}, [searchKeyword])

	useEffect(() => {
		console.log('selectLecture', selectLecture)
	}, [])

	useEffect(() => {

		setSelectedDays(selectLecture?.days)
		setFixName(selectLecture?.name)
		setFixTeacher(firstTeacher)
		setFixType(selectLecture?.type == '관리식' ? 1 : 2)
		setFixMaxCount(selectLecture?.maxCount)
		setFixTime(selectLecture?.time)
		setFixStart(selectLecture?.startedAt)
		setFixEnd(selectLecture?.endedAt)
	}, [selectLecture])

	useEffect(() => {
		const lecture = LectureListData?.find(i => i.id == id)

		if (lecture) {
			setSelectLecture(lecture)
			setIsTotal(false)
		} else {
			setSelectLecture(null)
		}
	}, [id])

	const toggleDay = (day) => {

		const binaryDay = convertDaysToBinary(day);
		setSelectedDays((prevSelectedDays) => {
			const newSelectedDaysArray = prevSelectedDays.split('');
			for (let i = 0; i < binaryDay.length; i++) {
				if (binaryDay[i] === '1') {
					newSelectedDaysArray[i] = newSelectedDaysArray[i] === '1' ? '0' : '1';
				}
			}
			return newSelectedDaysArray.join('');
		});
	};


	const DeleteLecture = (lectureId, className) => {
		const shouldDelete = window.confirm(`정말로 '${className}' 강의를 삭제하시겠습니까?`)

		if (shouldDelete) {
			API.delete('/lecture/' + lectureId, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
						message.success('삭제되었습니다')
						lectureMutate()
						setIsTotal(true)
					} else {
						if (res?.result?.message?.includes('foreign key')) {
							message.error('연결된 데이터가 있어 삭제할 수 없습니다.')
						} else {
							message.error(res?.result?.message || '삭제에 실패했습니다.')
						}
					}
				})
				.catch((err: any) => {})
		}
	}



	const SaveLecture = (lectureId) => {

		console.log('selectedDays', selectedDays)

		if ((selectedDays !== '0000000') && (fixTeacher !== '') ) {
			API.put('/lecture/' + lectureId, token + '', {
				datas: {
					name: fixName,
					teacherIds: [fixTeacher],
					type: fixType,
					maxCount: fixMaxCount,
					days: selectedDays,
					time: fixTime,
					startedAt: fixStart,
					endedAt: fixEnd,
				},
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success('수정되었습니다')
						setSelectLecture(prevSelectLecture => ({
							...prevSelectLecture,
							name: fixName,
							teacherNames: [firstTeacherName],
							type: fixType,
							maxCount: fixMaxCount,
							days: selectedDays,
							time: fixTime,
							startedAt: fixStart,
							endedAt: fixEnd,
						}))
						lectureMutate()
						setIsFix(false)
					}
				})
				.catch((err: any) => {})
		}
	}

	// ** 학생 추가 input 검색
	const searchAddStudent = (schoolType, grade) => {
		console.log(
			StudentAll,
			schoolType,
			StudentAll.filter(student => student.grade === schoolType),
			StudentAll.filter(student => student.grade === schoolType).filter(student =>
				student.name.toLowerCase()
			)
		)
		if (schoolType == '무학년') {
			return StudentAll.filter(student =>
				student.name.toLowerCase().includes(searchKeyword.toLowerCase())
			)
		}

		return StudentAll.filter(student => (student.schoolType === schoolType) && student.grade === grade).filter(student =>
			student.name.toLowerCase().includes(searchKeyword.toLowerCase())
		)
	}
	// ** 선택한 학생들 강의에 등록 =====
	const clickAddStudent = () => {
		if (selectLecture.totalCount > selectLecture.maxCount) {
			message.error('학생 정원을 초과했습니다.')
		} else {
			API.post('/lecture/' + selectLecture.id + '/student', token + '', {
				studentIds: AddStudent,
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success('학생이 추가되었습니다')
						setAddStudent([])
						setSearchKeyword('')
						setIsAdd(false)
						SelectLectureMutate()
						lectureMutate()
					}
				})
				.catch((err: any) => {})
		}
	}

	const handleClickOutsideSelect = event => {
		if (!event.target.closest('.selectLecture')) {
			setIsFix(false)
		}
		if (!event.target.closest('.Add')) {
			setIsAdd(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideSelect)
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideSelect)
		}
	}, [])

	useEffect(() => {
		console.log('LectureStudentsData', LectureStudentsData)
	}, [LectureStudentsData])



	const DeleteLectureStudent = (id, name) => {
		API.delete('/lecture/' + selectLecture.id + '/student?' + 'studentIds=' + id, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					message.success(`${name}이 삭제되었습니다`)
					SelectLectureMutate()
					lectureMutate()
				}
			})
			.catch((err: any) => {})
	}



return (
		<LectureWrapper>
			<LectureList
				setIsAddLecture={setIsAddLecture}
				setIsTotal={setIsTotal}
				setSelectLecture={setSelectLecture}
				selectLecture={selectLecture}
				selectGrade={selectGrade}
				setSelectGrade={setSelectGrade}
			/>
			<LectureView>
				{isTotal && (
					<TotalTable
						setIsTotal={setIsTotal}
						setSelectLecture={setSelectLecture}
						selectGrade={selectGrade}
					/>
				)}
				{isTotal == false && selectLecture && (
					<div className="selectLecture">
						<LectureViewHeader>
							<p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
								{!isFix ? (
									selectLecture.name
								) : (
									<input
										className="fixName"
										type="text"
										placeholder={selectLecture.name}
										value={fixName}
										onChange={e => setFixName(e.target.value)}
									/>
								)}
								<FixBtns>
									{!isFix ? (
										<>
											<button
												className="fix"
												onClick={() => {
													setIsFix(true)
												}}>
												수정
											</button>
											<button
												className="delete"
												onClick={() => DeleteLecture(selectLecture.id, selectLecture.name)}>
												삭제
											</button>
										</>
									) : (
										<>
											<button className="fix" onClick={() => SaveLecture(selectLecture.id)}>
												저장
											</button>
											<button className="delete" onClick={() => setIsFix(false)}>
												취소
											</button>
										</>
									)}
								</FixBtns>
							</p>
							{selectLecture.type == '강의식' && (
								<button className="lessonNoteBtn" onClick={() => setLectureSt(true)}>
									<Icon>
										<BsFillPencilFill />
									</Icon>
									<p>학습일지</p>
								</button>
							)}
						</LectureViewHeader>
						<LectureViewTable>
							<tbody>
								<tr>
									<th>요일</th>
									<th>학습시간</th>
									<th>강사</th>
									<th>방식</th>
									<th>개강일</th>
									<th>종강일</th>
									<th>정원</th>
								</tr>
								<tr>
									<td>
										{!isFix ? (
											convertBinaryToDays(String(selectLecture.days))
										) : (
											<div className="date">
												{['월', '화', '수', '목', '금', '토', '일'].map(day => (
													<p
														key={day}
														onClick={() => toggleDay(day)}
														className={textDays.includes(day) ? 'selected' : ''}>
														{day}
													</p>
												))}
											</div>
										)}
									</td>
									<td>
										{!isFix ? (
											selectLecture.time == '' || selectLecture.time == '00:00' ? (
												'-'
											) : selectLecture?.time?.slice(3, 5) === '00' ? (
												selectLecture?.time?.slice(0, 2) + '시간'
											) : (
												selectLecture?.time?.slice(0, 2) +
												'시간' +
												selectLecture?.time?.slice(3, 5) +
												'분'
											)
										) : (
											<input
												type="text"
												placeholder={`${selectLecture.time}`}
												onChange={e => setFixTime(e.target.value)}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											selectLecture.teacherNames?.join(', ')
										) : (
											<select
												style={{ minWidth: '100px' }}
												placeholder="변경없음"
												onChange={e => setFixTeacher(e.target.value)}>
												<option className="selectLecture" value="">
													변경없음
												</option>
												{TeacherStudentData?.map((it, idx) => (
													<option className="selectLecture" key={idx} value={it.id}>
														{it.name}
													</option>
												))}
											</select>
										)}
									</td>
									<td>
										{!isFix ? (
											selectLecture.type
										) : (
											<select onChange={e => setFixType(parseInt(e.target.value, 10))}>
												<option value="1">관리식</option>
												<option value="2">강의식</option>
											</select>
										)}
									</td>
									<td>
										{!isFix ? (
											selectLecture.startedAt
										) : (
											<input type="date" onChange={e => setFixStart(e.target.value)} />
										)}
									</td>
									<td>
										{!isFix ? (
											selectLecture.endedAt == '' ? (
												'-'
											) : (
												selectLecture.endedAt
											)
										) : (
											<input type="date" onChange={e => setFixEnd(e.target.value)} />
										)}
									</td>
									<td className="person">
										{!isFix ? (
											<>
												<span>
													{selectLecture.totalCount == null ? '0' : selectLecture.totalCount}
												</span>{' '}
												/{selectLecture.maxCount}
											</>
										) : (
											<input
												style={{ width: '60px' }}
												type="text"
												placeholder={`${
													selectLecture.maxCount == null ? '0' : selectLecture.maxCount
												}`}
												onChange={e => setFixMaxCount(e.target.value)}
											/>
										)}
									</td>
								</tr>
							</tbody>
						</LectureViewTable>
						<div className="scroll" style={{ height: '704.5px', position: 'relative' }}>
							<LectureViewTable>
								<tbody>
									<tr>
										<th>No.</th>
										<th>이름</th>
										<th>학교</th>
										<th>학교명</th>
										<th>학년</th>
										<th>학생번호</th>
										<th>수강시작</th>
										<th>수강종료</th>
										<th>비고</th>
										<th></th>
									</tr>
									{LectureStudentsData?.map((it, idx) => (
										<tr key={idx}>
											<td>{idx + 1}</td>
											<td className="nameNoteArea">
												{it.name}
												{selectLecture.type == '관리식' && (
													<button
														className="manageNoteBtn"
														onClick={() => {
															setSelectStudentNote(it)
															setManageSt(true)
															setEditNoteIndex({ id: selectLecture?.id, student: it.id })
															LectureStudentsDashboardDataMutate();
														}}
														style={{
															border: '1px solid #EFA44F',
															backgroundColor: DashboardLectureStudentsData[idx]?.reportStatus === 1
																? '#EFA44F'
																: 'transparent',
															color: DashboardLectureStudentsData[idx]?.reportStatus === 1
																? '#FFF'
																: '#EFA44F',
														}}>
														<Icon>
															{DashboardLectureStudentsData[idx]?.reportStatus === 1  ? (
																<FaCheck />
															) : (
																<BsFillPencilFill />
															)}
														</Icon>
														<p style={{ fontSize: '14px' }}>학습일지</p>
													</button>
												)}
											</td>
											<td>{it.schoolType == '' ? '-' : it.schoolType}</td>
											<td>{it.school == '' ? '-' : it.school}</td>
											<td>{it.grade}</td>
											<td>{it.phone == '' ? '-' : it.phone}</td>
											<td>{it.startedAt}</td>
											<td>{it.endedAt == '' ? '-' : it.endedAt}</td>
											<td>{it.memo == '' ? '-' : it.memo}</td>
											<td>{<button style={{backgroundColor:'#e54848', border:"transparent", borderRadius:5, color:'white', padding: '5px 8px'}} onClick={() => {DeleteLectureStudent(it.id, it.name)}}>삭제</button>}</td>
										</tr>
									))}
								</tbody>
							</LectureViewTable>
							{isAdd ? (
								<IsAdd className="Add">
									<div className="AddHeader">
										<LectureListSearch style={{ width: '100%' }}>
											<input
												type="text"
												placeholder="학생검색"
												value={searchKeyword}
												onChange={e => setSearchKeyword(e.target.value)}
											/>
											<Icon style={{ color: '#DDAFAF', scale: '1.5' }}>
												<CiSearch />
											</Icon>
										</LectureListSearch>
										<div className="btns">
											<button className="add" onClick={clickAddStudent}>
												추가
											</button>
											<button className="cancel" onClick={() => setIsAdd(false)}>
												취소
											</button>
										</div>
									</div>
									<div className="AddTable" style={{ height: '100%' }}>
										<LectureViewTable>
											<tbody>
												<tr>
													<th>이름</th>
													<th>학교명</th>
													<th>학교</th>
													<th>학년</th>
												</tr>
												{searchAddStudent(selectLecture.schoolType, selectLecture.grade)?.length !== 0 ? (
													searchAddStudent(selectLecture.schoolType, selectLecture.grade)?.map((it, idx) => (
														<tr
															className="student"
															key={idx}
															onClick={() =>
																setAddStudent(prevStudents => {
																	const updatedStudents = prevStudents.includes(it.id)
																		? prevStudents.filter(id => id !== it.id)
																		: [...prevStudents, it.id]
																	return updatedStudents
																})
															}
															style={{
																backgroundColor: AddStudent.includes(it.id)
																	? 'rgba(247, 139, 133, 0.5)'
																	: 'transparent',
															}}>
															<td>{it.name}</td>
															<td>{it.school}</td>
															<td>{it.schoolType}</td>
															<td>{it.grade}</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan={6}>
															<p>해당학년에 등록된 학생이 없습니다.</p>
														</td>
													</tr>
												)}
											</tbody>
										</LectureViewTable>
									</div>
								</IsAdd>
							) : (
								<IsAddBtn onClick={() => setIsAdd(true)}>
									<FaPlus />
									<p>추가</p>
								</IsAddBtn>
							)}
						</div>
					</div>
				)}
			</LectureView>
			{isAddLecture && <AddLectureModal setIsAddLecture={setIsAddLecture} />}
		</LectureWrapper>
	)
}

export default React.memo(Lecture)
