import React, {useEffect, useState} from 'react'
import { Blacked, Icon } from '../organism/Navigation/styles'
import { TiArrowSortedDown } from 'react-icons/ti'
import { FaChevronLeft } from 'react-icons/fa6'
import { message } from 'antd'
import {
	ManageNote,
	ManageStyleModalMain,
	NoteTable,
	TableHeader,
	NoteHeader,
	TextArea,
	NoteTableWrapper,
	MobileTable,
} from './styles/modalStyles'
import { useLearningNote } from '../hooks/LearningNote'
import { AddLectureLearningNote } from './AddLectureLearningNote'
import { useToken } from 'app/hooks'
import API from 'utils/api'

export const LectureStyleModal = ({ selectLecture, setLectureSt }) => {
	const { data: token } = useToken()
	const { LearningNoteData, mutate: LearningNoteMutate, changeDateFilter } = useLearningNote(selectLecture?.id)
	const LearningNote = LearningNoteData?.filter(it => it.type === 1) || []
	const monthNote = LearningNoteData?.filter(it => it.type === 2) || []
	const [isAdd, setIsAdd] = useState(false)
	const [isAddMobile, setIsAddMobile] = useState(false)
	const [isFix, setIsFix] = useState(false)
	const [editingIndex, setEditingIndex] = useState(null)
	const [fixContent, setFixContent] = useState('')
	const [fixHomework, setFixHomework] = useState()
	const [fixMemo, setFixMemo] = useState()
	const [fixMonthNote, setFixMonthNote] = useState(monthNote[0]?.content || '')

	const now = new Date()
	const year = now.getFullYear()
	const month = now.getMonth() + 1
	const day = now.getDate()
	const sliceYear = year.toString()?.slice(-2)
	const formattedMonth = month < 10 ? `0${month}` : month
	const formattedDay = day < 10 ? `0${day}` : day
	const hours = now.getHours().toString().padStart(2, '0')
	const minutes = now.getMinutes().toString().padStart(2, '0')
	const formattedTime = `${year}-${formattedMonth}-${formattedDay} ${hours}:${minutes}`

	const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false)
	const [selectedMonth, setSelectedMonth] = useState(month)
	const [dateArrangeData, setDateArrangeData] = useState('')
	const [monthNoteDate, setMonthNoteDate] = useState('')
	const [todayMonthNoteDate, setTodayMonthNoteDate] = useState('')

	const apiurl = '/dashboard/lecture/' + selectLecture?.id + '/report/'

	useEffect(() => {
		const today = new Date();  // 현재 날짜 및 시간을 가져옵니다.
		today.setFullYear(year);  // 현재 년도 설정 (연도 상태 변수를 사용)
		today.setMonth(selectedMonth - 1, 1);  // 선택된 월 설정, 날짜를 1일로 설정합니다.

		// 해당 월의 마지막 날짜를 구합니다.
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

		// 연도는 두 자리로 표현합니다.
		const sliceYear = today.getFullYear().toString()?.slice(-2);
		const formattedMonth = (today.getMonth() + 1).toString().padStart(2, '0');

		// 상태 업데이트 (상단의 코드에 이미 선언된 변수와 충돌하지 않도록 새로운 변수명 사용)
		const newFormattedMonthRange = `${sliceYear}-${formattedMonth}-01 ~ ${sliceYear}-${formattedMonth}-${lastDay}`;

		setDateArrangeData(newFormattedMonthRange);  // 날짜 필터 함수를 업데이트하는 훅 (서버 요청 등에 사용될 수 있습니다.)
	}, [selectedMonth, year]);



	useEffect(() => {
		setTimeout(() => {
			setFixMonthNote(monthNote[0]?.content || '')
			LearningNoteMutate()
		}, 100)
	}, [selectLecture, LearningNoteData, selectedMonth]);


	const handleSaveButtonClick = () => {
		if (monthNote?.length == 0) {
			API.post(apiurl, token + '', {
				type: 2,
				createdAt: todayMonthNoteDate === monthNoteDate ? '' : monthNoteDate,
				content: fixMonthNote,
				homework: '',
				memo: '',
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success('저장되었습니다')
						LearningNoteMutate()
						setLectureSt(false)
					}
				})
				.catch((err: any) => {})
		} else {
			API.put(apiurl + monthNote[0]?.id, token + '', {
				datas: {
					content: fixMonthNote,
					createdAt: todayMonthNoteDate === monthNoteDate ? '' : monthNoteDate,
					homework: '',
					memo: '',
				},
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success('저장되었습니다')
						LearningNoteMutate()
						setLectureSt(false)
					}
				})
				.catch((err: any) => {})
		}
	}

	// ** 수정 버튼 클릭 시 해당 인덱스 요소만 수정 가능하게
	const handleFixBtnClick = idx => {
		setEditingIndex(idx)
		setIsFix(true)
		setFixContent(LearningNote[idx].content)
		setFixHomework(LearningNote[idx].homework)
		setFixMemo(LearningNote[idx].memo)
	}
	// ** 수정 -> 저장 버튼으로 변경 후 API로 데이터 이동
	const handleFixSaveClick = noteId => {
		API.put(apiurl + noteId, token + '', {
			datas: {
				content: fixContent,
				createdAt: formattedTime,
				homework: fixHomework,
				memo: fixMemo,
			},
		})
			.then((res: any) => {
				if (res.result.success) {
					message.success('수정되었습니다')
					setIsFix(false)
					setEditingIndex(null)
					LearningNoteMutate()
				}
			})
			.catch((err: any) => {})
	}
	const handleDeleteBtnClick = noteId => {
		API.delete(apiurl + noteId, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					message.success('삭제되었습니다')
					LearningNoteMutate()
				}else{
					message.error('삭제에 실패했습니다')
				}
			})
			.catch((err: any) => {})
	}

	const saveFixContent = e => {
		setFixContent(e.target.value)
	}
	const saveFixHomework = e => {
		setFixHomework(e.target.value)
	}
	const saveFixMemo = e => {
		setFixMemo(e.target.value)
	}
	const saveFixMonthNote = e => {
		setFixMonthNote(e.target.value)
	}

	const handleMonthSelect = selectedMonth => {
		setSelectedMonth(selectedMonth)
		setIsMonthDropdownOpen(false)
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter' && !e.shiftKey) {
			// 엔터 키를 누르고, shift 키를 누르지 않은 경우
			e.preventDefault() // 기본 엔터 동작 방지
			setFixContent(prevContent => prevContent + '\n')
		}
	}

	useEffect(() => {
		const today = now
		today.setMonth(selectedMonth - 1)

		//2024-04
		changeDateFilter(today.toISOString()?.slice(0, 7))
		setMonthNoteDate(today.toISOString()?.slice(0, 7))
		setTodayMonthNoteDate(formattedTime?.slice(0, 7))
	}, [selectedMonth])

	const printContent = (LearningNote, monthNote, selectedMonth, selectLecture) => {
		const printWindow = window.open('', '_blank', 'width=800,height=600');

		const styles = `
    <style>
    body { font-family: Arial, sans-serif; }
    .tableHeader { font-weight: bold; }
    .teacherArea { margin-top: 20px; }
    
    .mobileTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    .tableRow {
      display: flex;
      align-items: center;
      width: 100%;
      .tableEl {
        width: 50%;
        display: flex;
        align-items: center;
      }
    }
    .th {
      border: 1px solid #F6F1F1;
      background-color: #FFF5F5;
      color: #666666;
      font-size: 13px;
      text-align: center;
      font-weight: 400;
      letter-spacing: -1px;
      padding: 15px 10px;
      width: 60px;
    }
    .td {
      padding: 0 12px;
      font-size: 15px;
      letter-spacing: -1px;
      color: #111111;
      font-weight: 400;
    }
  }
  
  .tableHeader {
      background-color: #FAE7E7;
      padding: 0 12px;
      border-radius: 4px 4px 0 0;
      display: flex;
      height: 36px;
      justify-content: space-between;
      align-items: center;
      p {
        &:first-child {
          font-size: 15px;
          font-weight: 500;
          color: #111111;
        }
        &:last-child {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }
      }
    }

.tableMain {
      display: flex;
      flex-direction: column;
      gap: 3px;
      border: 1px solid #F6F1F1;
      border-top: none;
      background-color: #FAFAFA;
      .content, .assignment {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
      }
      .mobileContent {
        p {
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          textarea {
            width: 100%;
            min-height: 100px;
            resize: none;
            border: none;
            padding: 10px;
            background-color: #FAFAFA;
            &::placeholder {
              color: #AFAFAF;
              font-size: 15px;
              letter-spacing: -1px;
            }
          }
        }
      }
      .assignment {
        border-top: 1px solid #F6F1F1;
        border-radius: 0 0 4px 4px;
      }
      h1 {
        color: #666666;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -1px;
        width: 48px;
        padding: 10px 12px;
      }
      p {
        padding: 10px 12px;
        height: 100%;
        width: 100%;
        input {
          height: 100%;
          width: 100%;
          border: none;
          background-color: transparent;
          padding: 0px 10px;
          &::placeholder {
            color: #AFAFAF;
            font-size: 15px;
            letter-spacing: -1px;
          }
        }
      }
      @media print {
        border-top: 1px solid #F6F1F1;
        .assignment {
          border-top: 1px solid #F6F1F1;
        }
      }
    }
    
 .content {
    text-align: left;
    line-height: normal;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      textarea {
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #111111;
    font-size: 22px;
    letter-spacing: -1px;
    font-weight: 600;
    cursor: pointer;
    span {
      text-decoration: underline;
    }
    p {
      &:first-child {
        margin-right: 4px;
      }
      &:last-child {
        color: #888888;
        font-size: 18px;
      }
    }
    .selectMonth {
      position: absolute;
      display: flex;
      align-items: center;
      top: 43px;
      gap: 6px;
      p {
        background-color: #F3F3F3;
        padding: 4px 7px;
        border-radius: 2px;
        color: #111111;
        font-size: 16px;
        font-weight: 400;
        &:hover {
          background-color: #F78B85;
          color: #fff;
        }
      }
    }
  }

.tableHeader {
      background-color: #FAE7E7;
      padding: 0 12px;
      border-radius: 4px 4px 0 0;
      display: flex;
      height: 36px;
      justify-content: space-between;
      align-items: center;
      p {
        &:first-child {
          font-size: 15px;
          font-weight: 500;
          color: #111111;
        }
        &:last-child {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }
      }
 }
 
 .teacherArea {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #111111;
      font-size: 16px;
      font-weight: 500;
      .vertical {
        margin: 0 10px;
        color: #CDCDCD;
        font-size: 16px;
        font-weight: 500;
      }
  }
    
  .customTextArea {
    width: 100%;
    height: 130px;
    padding: 12px 16px;
    border-width: 0px, 1px, 1px, 1px;  
    border-style: solid;
    border-color: #F6F1F1;
    resize: none;
    &::placeholder {
     color: #AFAFAF;
        font-size: 16px;
    }
    @media (max-width: 1032px) {
     margin-top: -25px;
    }
    }
    
    .bottomHeader{
    display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #FFF5F5;
  p {
    &:first-child {
      color: #333333;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -1px;
    }
    &:last-child {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -1px;
    }
  }
  @media (max-width: 1023px) {
    margin-bottom: 26px;
  }
    }
    
    
    .titleHeader{
    display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #FFF5F5;
  p {
    &:first-child {
      color: #333333;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -1px;
    }
    &:last-child {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -1px;
    }
  }
  @media (max-width: 1023px) {
    margin-bottom: 26px;
  }
    }
    
     .modalBottom{
    margin-top: 40px;
    }
    
    </style>
  `;

		// 새 창에 HTML과 CSS를 작성합니다.
		printWindow?.document.write(`
    <html>
      <head>
        <title>Print</title>
        ${styles}
      </head>
      <body>
        <div id="printArea">
         <div class="title" >
            <p>${selectLecture?.name || ''}</p>
            <span>${selectedMonth}월</span>
            <p>학습일지</p>
          </div>
          <div class="titleHeader">
          <p>${selectLecture?.name || ''}</p>
              <p>
                ${dateArrangeData}
              </p>
          </div>
          ${LearningNote?.map((it, idx) => `
            <div class="mobileTable">
              <div class="tableHeader">
                <p>${idx + 1}회차</p>
                <p>${it.createdAt  || ''}</p>
              </div>
              <div class="tableMain">
                <div class="content">
                  <h1>학습 내용</h1>
                  <p>${it.content  || ''}</p>
                </div>
                <div class="assignment">
                  <h1>과제</h1>
                  <p>${it.homework  || ''}</p>
                </div>
              </div>
            </div>
          `).join('')}
          <div class="modalBottom">
            <div class="bottomHeader">
              <p>월간 학습현황</p>
              <p>${monthNote.length === 0 ? '' : `작성일: ${monthNote[0].createdAt  || ''}`}</p>
            </div>
            <textarea class="customTextArea">${monthNote.length === 0 ? '' : monthNote[0].content}</textarea>
          </div>
          <div class="teacherArea">
            <p>담당 강사: ${selectLecture?.teacherNames?.join(', ')  || ''}</p>
          </div>
        </div>
      </body>
    </html>
  `);

		printWindow?.document.close(); // 문서 작성을 완료합니다.
		printWindow?.focus(); // 새 창에 포커스를 맞춥니다.
		printWindow?.print(); // 인쇄 대화 상자를 엽니다.
		printWindow?.close(); // 인쇄가 완료된 후 창을 닫습니다.
	}


	return (
		<ManageStyleModalMain>
			<Blacked onClick={() => setLectureSt(false)} />
			<ManageNote>
				<NoteHeader>
					<button
						className="message"
						onClick={() => {
							if (window.confirm('문자전송을 하시겠습니까?')) {

								API.post('/dashboard/message', token + '', {
									type: 'report',
									lectureId: selectLecture?.id,
									date: '2025-' + selectedMonth,
								})
									.then((res: any) => {
										if (res.result.success) {
											message.success('문자전송이 완료되었습니다')
										} else {
											message.error(res?.result?.message || '문자전송에 실패했습니다.')
										}
									})
									.catch((err: any) => {
									})
							}
						}}
					>문자전송</button>
					<Icon
						onClick={() => setLectureSt(false)}
						style={{
							width: '24px',
							height: '24px',
							color: '#666666',
						}}>
						<FaChevronLeft />
					</Icon>
					<div className="title" onClick={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)}>
						<p>{selectLecture.name}</p>
						<span>{selectedMonth}월</span>
						{isMonthDropdownOpen && (
							<div className="selectMonth">
								{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m => (
									<p
										key={m}
										onClick={() => handleMonthSelect(m)}
										style={{
											backgroundColor: selectedMonth == m ? '#F78B85' : '',
											color: selectedMonth == m ? '#FFF' : '',
										}}>
										{m}월
									</p>
								))}
							</div>
						)}
						<p>학습일지</p>
						<p>
							<TiArrowSortedDown />
						</p>
					</div>
					<div className="rightBtns">
						<span onClick={() => printContent(LearningNote, monthNote, selectedMonth, selectLecture)}>인쇄</span>
						<button
							className="mobileMessage"
							onClick={() => {
								if (window.confirm('문자전송을 하시겠습니까?')) {

									API.post('/dashboard/message', token + '', {
										type: 'report',
										lectureId: selectLecture?.id,
										date: '2025-' + selectedMonth,
									})
										.then((res: any) => {
											if (res.result.success) {
												message.success('문자전송이 완료되었습니다')
											} else {
												message.error(res?.result?.message || '문자전송에 실패했습니다.')
											}
										})
										.catch((err: any) => {
										})
								}
							}}
						>전송</button>
						<button onClick={handleSaveButtonClick}>저장</button>
					</div>
				</NoteHeader>
				<div className="modalMain">
					<div className="tableWrapper">
						<TableHeader>
							<p>{selectLecture?.name}</p>
							<p>
								{dateArrangeData}
							</p>
						</TableHeader>
						<NoteTableWrapper>
							<NoteTable>
								<tr>
									<th style={{ minWidth: '80px' }}>회차</th>
									<th style={{ minWidth: '100px' }}>일자</th>
									<th style={{ width: '60%' }}>학습내용</th>
									<th style={{ width: '20%' }}>과제</th>
									<th style={{ width: '20%' }}>비고</th>
									<th></th>
								</tr>
								{LearningNote?.map((it, idx) => (
									<tr key={idx} className="learningNote">
										<td>{idx + 1}</td>
										<td className="date">{it.createdAt}</td>
										<td>
											{editingIndex !== idx ? (
												it.content
											) : (
												<div>
													<textarea
														style={{
															width: '100%',
														}}
														className="contentInput"
														autoFocus
														value={fixContent}
														placeholder="학습내용 입력(필수) &#13;&#10;줄바꿈은 shift + Enter"
														onChange={saveFixContent}
														onKeyDown={handleKeyDown}
													/>
												</div>
											)}
										</td>
										<td>
											{editingIndex !== idx ? (
												it.homework
											) : (
												<textarea
													placeholder="과제 수정"
													value={fixHomework}
													onChange={saveFixHomework}
												/>
											)}
										</td>
										<td style={{position: 'relative' }}>
											{editingIndex !== idx ? (
												it.memo
											) : (
												<input
													style={{position: "relative", top: -82}}
													type="text"
													placeholder="비고 수정"
													value={fixMemo}
													onChange={saveFixMemo}
												/>
											)}
										</td>

										{(editingIndex !== idx ) ? (
											<div className="btns2">
												<button className="fixBtn" onClick={() => handleFixBtnClick(idx)}>
													수정
												</button>
												<button className="deleteBtn" onClick={() => handleDeleteBtnClick(it.id)}>
													삭제
												</button>
											</div>
										) : (
											<div className="btns2" style={{position: "relative", top: -82}}>
												<button className="fixBtn" onClick={() => handleFixSaveClick(it.id)}>
													저장
												</button>
												<button
													className="deleteBtn"
													onClick={() => {
														setEditingIndex(null)
														setIsFix(false)
													}}>
													취소
												</button>
											</div>
										)}
									</tr>
								))}
							</NoteTable>
							{LearningNote?.map((it, idx) => (
								<MobileTable key={idx}>
									<div className="tableHeader">
										<p>{idx + 1}회차</p>
										<p>{it.createdAt}</p>
									</div>
									<div className="tableMain">
										<div className="content">
											<h1>
												학습
												<br />
												내용
											</h1>
											<p>{it.content}</p>
										</div>
										<div className="assignment">
											<h1>과제</h1>
											<p>
												{it.homework == '' ? (
													<textarea className="assignmentMobile" placeholder="내용작성" />
												) : (
													it.homework
												)}
											</p>
										</div>
									</div>
								</MobileTable>
							))}
						</NoteTableWrapper>
						<AddLectureLearningNote
							isAdd={isAdd}
							setIsAdd={setIsAdd}
							isAddMobile={isAddMobile}
							setIsAddMobile={setIsAddMobile}
							selectLecture={selectLecture}
							formattedTime={formattedTime}
							todayMonthNoteDate={todayMonthNoteDate}
							monthNoteDate={monthNoteDate}
						/>
					</div>
					<div className="modalBottom">
						<TableHeader>
							<p style={{ fontSize: '16px' }}>월간 학습현황</p>
							<p>{monthNote.length == 0 ? '' : `작성일 : ${monthNote[0]?.createdAt}`}</p>
						</TableHeader>
						<TextArea placeholder="내용작성" onChange={saveFixMonthNote} value={fixMonthNote} />
					</div>
					<div className="teacherArea">
						<p>담당 강사 : {selectLecture?.teacherNames?.join(', ')}</p>
					</div>
				</div>
			</ManageNote>
		</ManageStyleModalMain>
	)
}

export default React.memo(LectureStyleModal)
