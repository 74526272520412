import React, { useEffect, useState } from 'react'
import {
	FixBtns,
	LectureListSearch,
	LectureView,
	LectureViewHeader,
	LectureViewTable,
	LectureWrapper,
	StudentViewTable,
} from '../Lecture/styles'
import StudentList from './StudentList'
import StudentTotalTable from './StudentTotalTable'
import AddStudentModal from 'app/gomsacademy/components/AddStudentModal'
import { Icon, IsAdd, IsAddBtn } from '../Home/styles'
import { FaCheck } from 'react-icons/fa6'
import { BsFillPencilFill } from 'react-icons/bs'
import { useGetUser } from 'app/gomsacademy/hooks/GetUser'
import { useGetUserClass } from 'app/gomsacademy/hooks/GetClassList'
import API from 'utils/api'
import { useToken } from 'app/hooks'
import { Input, message } from 'antd'
import { useTeacherStudent } from 'app/gomsacademy/hooks/TeacherStudentList'
import { convertBinaryToDays } from 'utils/ciutils'
import { useLocation } from 'react-router-dom'
import { useLectureList } from 'app/gomsacademy/hooks/LectureList'
import { FaPlus } from 'react-icons/fa6'
import { CiSearch } from 'react-icons/ci'
import TextArea from 'antd/es/input/TextArea'

export const Student = ({
	setSelectLecture,
	isWrite,
	setManageSt,
	setSelectStudentNote,
	setEditNoteIndex,
													selectLecture
}) => {
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const id = params.get('id')

	const { data: token } = useToken()
	const { mutate: StudentAllMutate } = useTeacherStudent(3)
	// userId 받아오기
	const [selectStudent, setSelectStudent] = useState('')
	const { UserData, mutate: StudentMutate } = useGetUser(selectStudent)
	const { UserClassData, mutate: userClassMutate } = useGetUserClass(selectStudent)
	const { LectureListData } = useLectureList(0)

	const [isTotal, setIsTotal] = useState(true)
	const [isAddStudent, setIsAddStudent] = useState(false)
	const [isFix, setIsFix] = useState(false)
	const [isAdd, setIsAdd] = useState(false)
	const [searchKeyword, setSearchKeyword] = useState('')
	const [AddLecture, setAddLecture] = useState(0)
	const [selectedGrade, setSelectedGrade] = useState('전체')

	const [fixName, setFixName] = useState(UserData?.name || '')
	const [fixSchoolType, setFixSchoolType] = useState(UserData?.schoolType || '')
	const [fixSchool, setFixSchool] = useState(UserData?.school || '')
	const [fixGrade, setFixGrade] = useState<number>(UserData?.grade || 0)
	const [fixPhone, setFixPhone] = useState(UserData?.phone || '')
	const [fixPhone2, setFixPhone2] = useState(UserData?.phone2 || '')
	const [fixCreatedAt, setFixCreatedAt] = useState(UserData?.createdAt || '')
	const [fixDays, setFixDays] = useState(UserData?.days || '')
	const [fixStatus, setFixStatus] = useState(UserData?.status || '1')
	const [fixMemo, setFixMemo] = useState(UserData?.memo || '')

	useEffect(() => {
		if (id) {
			setSelectStudent(id)
			setIsTotal(false)
		}
	}, [id])


	useEffect(() => {
		if(UserData) {
			setFixName(UserData?.name);
			setFixSchoolType(UserData?.schoolType);
			setFixSchool(UserData?.school);
			setFixGrade(UserData?.grade );
			setFixPhone(UserData?.phone);
			setFixPhone2(UserData?.phone2);
			setFixCreatedAt(UserData?.createdAt);
			setFixDays(UserData?.days);
			setFixStatus(UserData?.status);
			setFixMemo(UserData?.memo );
		}
	}, [UserData])

	const DeleteStudent = (userId, userName) => {
		const shouldDelete = window.confirm(`정말로 '${userName}' 학생을 삭제하시겠습니까?`)

		if (shouldDelete) {
			API.delete('/users/' + userId, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
						StudentAllMutate()
						message.success('삭제되었습니다')
						setIsTotal(true)
					} else {
						if (res?.result?.message?.includes('foreign key')) {
							message.error('연결된 데이터가 있어 삭제할 수 없습니다.')
						} else {
							message.error(res?.result?.message || '삭제에 실패했습니다.')
						}
					}
				})
				.catch((err: any) => {})
		}
	}

	const SaveStudent = userId => {
		API.put('/users/' + userId, token + '', {
			datas: {
				name: fixName,
				phone: fixPhone,
				createdAt: fixCreatedAt,
				school: fixSchool,
				schoolType: fixSchoolType,
				grade: fixGrade,
				phone2: fixPhone2,
				status: fixStatus,
				memo: fixMemo,
			},
		})
			.then((res: any) => {
				if (res.result.success) {
					StudentMutate()
					message.success('수정되었습니다')
					setIsFix(false)
				}
			})
			.catch((err: any) => {})
	}

	// ** 강의 추가 input 검색
	const searchAddLecture = () => {
		return LectureListData.filter(lecture =>
			lecture.name.toLowerCase().includes(searchKeyword.toLowerCase())
		)
	}
	// ** 선택한 강의를 학생에 등록 =====
	const clickAddStudent = () => {
		API.post('/lecture/' + AddLecture + '/student', token + '', {
			studentIds: [selectStudent],
		})
			.then((res: any) => {
				if (res.result.success) {
					message.success('강의가 추가되었습니다')
					setSearchKeyword('')
					userClassMutate()
					setIsAdd(false)
				}
			})
			.catch((err: any) => {})
	}

	// ** 수정 누르고 다른 곳 클릭하면 닫힘
	const handleClickOutsideSelect = event => {
		if (!event.target.closest('.selectStudent')) {
			setIsFix(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideSelect)
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideSelect)
		}
	}, [])

	return (
		<LectureWrapper>
			<StudentList
				setIsAddStudent={setIsAddStudent}
				setIsTotal={setIsTotal}
				selectStudent={selectStudent}
				setSelectStudent={setSelectStudent}
				selectedGrade={selectedGrade}
				setSelectedGrade={setSelectedGrade}
				setSelectStudentNote={setSelectStudentNote}
			/>
			<LectureView>
				{isTotal && (
					<StudentTotalTable
						selectLecture={selectLecture}
						setIsTotal={setIsTotal}
						setSelectStudent={setSelectStudent}
						selectedGrade={selectedGrade}
						setSelectStudentNote={setSelectStudentNote}
						setManageSt={setManageSt}
						setEditNoteIndex={setEditNoteIndex}
						setSelectLecture={setSelectLecture}
					/>
				)}
				{isTotal == false && UserData && (
					<div className="selectStudent" style={{ position: 'relative' }}>
						<LectureViewHeader>
							<p>
								{!isFix ? (
									UserData?.name
								) : (
									<Input
										className="fixName"
										type="text"
										placeholder={UserData?.name}
										onChange={e => setFixName(e.target.value)}
									/>
								)}
							</p>
							<FixBtns>
								{!isFix ? (
									<>
										<button className="fix" onClick={() => setIsFix(true)}>
											수정
										</button>
										<button
											className="delete"
											onClick={() => DeleteStudent(UserData?.id, UserData?.name)}>
											삭제
										</button>
									</>
								) : (
									<>
										<button className="fix" onClick={() => SaveStudent(UserData?.id)}>
											저장
										</button>
										<button className="delete" onClick={() => setIsFix(false)}>
											취소
										</button>
									</>
								)}
							</FixBtns>
						</LectureViewHeader>
						<StudentViewTable>
							<tbody className="studentTbody">
								<tr>
									<th>학교</th>
									<th>학교명</th>
									<th>학년</th>
									<th>학생번호</th>
									<th>학부모번호</th>
									<th>등록일</th>
									<th>등원요일</th>
									<th>재원여부</th>
								</tr>
								<tr>
									<td>
										{!isFix ? (
											UserData?.schoolType == '' ? (
												'-'
											) : (
												UserData?.schoolType
											)
										) : (
											<input
												type="text"
												placeholder={UserData?.schoolType}
												onChange={e => setFixSchoolType(e.target.value)}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.school == '' ? (
												'-'
											) : (
												UserData?.school
											)
										) : (
											<input
												type="text"
												placeholder={UserData?.school}
												onChange={e => setFixSchool(e.target.value)}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.grade
										) : (
											<input
												type="text"
												placeholder={UserData?.grade}
												onChange={e => setFixGrade(parseInt(e.target.value))}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.phone == '' ? (
												'-'
											) : (
												UserData?.phone
											)
										) : (
											<input
												type="text"
												placeholder={UserData?.phone}
												onChange={e => setFixPhone(e.target.value)}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.phone2 == '' ? (
												'-'
											) : (
												UserData?.phone2
											)
										) : (
											<input
												type="text"
												placeholder={UserData?.phone2}
												onChange={e => setFixPhone2(e.target.value)}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.createdAt
										) : (
											<input
												type="date"
												placeholder={UserData?.createdAt}
												onChange={e => setFixCreatedAt(e.target.value)}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											convertBinaryToDays(UserData?.days)
										) : (
											<input
												type="text"
												placeholder={convertBinaryToDays(UserData?.days)}
												onChange={e => setFixDays(e.target.value)}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.status == 1 ? (
												'Y'
											) : (
												'N'
											)
										) : (
											<select
												style={{ minWidth: '40px' }}
												onChange={e => setFixStatus(e.target.value)}>
												<option value="1">Y</option>
												<option value="0">N</option>
											</select>
										)}
									</td>
								</tr>
							</tbody>
							{/* ===== 모바일 일 때 ===== */}
							<div className="mobile">
								<div>
									<div>
										<p className="th">학교</p>
										<p className="td">
											{!isFix ? (
												UserData?.schoolType == '' ? (
													'-'
												) : (
													UserData?.schoolType
												)
											) : (
												<input
													type="text"
													placeholder={UserData?.schoolType}
													onChange={e => setFixSchoolType(e.target.value)}
												/>
											)}
										</p>
									</div>
									<div>
										<p className="th">학년</p>
										<p className="td">
											{!isFix ? (
												UserData?.grade
											) : (
												<input
													type="text"
													placeholder={UserData?.grade}
													onChange={e => setFixGrade(parseInt(e.target.value))}
												/>
											)}
										</p>
									</div>
								</div>
								<div>
									<div>
										<p className="th">학교명</p>
										<p className="td">
											{!isFix ? (
												UserData?.school
											) : (
												<input
													type="text"
													placeholder={UserData?.school}
													onChange={e => setFixSchool(e.target.value)}
												/>
											)}
										</p>
									</div>
									<div>
										<p className="th">등원요일</p>
										<p className="td">
											{!isFix ? (
												convertBinaryToDays(UserData?.days)
											) : (
												<input
													type="text"
													placeholder={convertBinaryToDays(UserData?.days)}
													onChange={e => setFixDays(e.target.value)}
												/>
											)}
										</p>
									</div>
								</div>
								<div>
									<div>
										<p className="th">수업과정</p>
										<p className="td">{UserData?.classCount}</p>
									</div>
									<div>
										<p className="th">재원여부</p>
										<p className="td">
											{!isFix ? (
												UserData?.status == 1 ? (
													'Y'
												) : (
													'N'
												)
											) : (
												<select
													style={{ minWidth: '40px' }}
													onChange={e => setFixStatus(e.target.value)}>
													<option value="1">Y</option>
													<option value="0">N</option>
												</select>
											)}
										</p>
									</div>
								</div>
								<div>
									<p className="th">등록일</p>
									<p className="td">
										{!isFix ? (
											UserData?.createdAt
										) : (
											<input
												type="date"
												placeholder={UserData?.createdAt}
												onChange={e => setFixCreatedAt(e.target.value)}
											/>
										)}
									</p>
								</div>
								<div>
									<p className="th">학생번호</p>
									<p className="td">
										{!isFix ? (
											UserData?.phone
										) : (
											<input
												type="text"
												placeholder={UserData?.phone}
												onChange={e => setFixPhone(e.target.value)}
											/>
										)}
									</p>
								</div>
								<div>
									<p className="th">학부모번호</p>
									<p className="td">
										{!isFix ? (
											UserData?.phone2
										) : (
											<input
												type="text"
												placeholder={UserData?.phone2}
												onChange={e => setFixPhone2(e.target.value)}
											/>
										)}
									</p>
								</div>
								<div>
									<p className="th">비고</p>
									<p
										className="td"
										style={{
											whiteSpace: 'pre-wrap',
										}}>
										{!isFix ? (
											UserData?.memo
										) : (
											<TextArea
												value={UserData?.memo}
												style={{ minHeight: 200, height: 300 }}
												onChange={e => setFixMemo(e.target.value)}
											/>
										)}
									</p>
								</div>
							</div>
						</StudentViewTable>
						<div
							style={{
								width: '100%',
								textAlign: 'left',
								flex: 1,
								minHeight: 80,
								padding: 5,
								background: '#efefef',
								border: '1px solid #ccc',
                whiteSpace: 'pre-wrap',
							}}>
							<p style={{ marginBottom: 5, fontWeight: 'bold', fontSize: 12 }}>비고</p>
							{!isFix ? (
								UserData?.memo
							) : (
								<textarea
									defaultValue={UserData?.memo}
									onChange={e => setFixMemo(e.target.value)}
									style={{ width: '100%', flex: 1 }}
								/>
							)}
						</div>
						<LectureViewTable>
							<tbody>
								<tr>
									<th>No.</th>
									<th>강의</th>
									<th>학교</th>
									<th>요일</th>
									<th>시간</th>
									<th>강사</th>
									<th>방식</th>
									<th>개강일</th>
									<th>종강일</th>
								</tr>
								{UserClassData?.map((it, idx) => (
									<tr key={idx}>
										<td>{idx + 1}</td>
										<td className="lecture">
											{it.name}
											{it.type == '관리식' && (
												<button
													className="manageNoteBtn"
													onClick={() => {
														setSelectLecture(it)
														setManageSt(true)
														setEditNoteIndex({ id: it.id, student: selectStudent })
													}}
													style={{
														border: '1px solid #EFA44F',
														backgroundColor: isWrite.find(
															entry => entry.id === it.id && entry.student === selectStudent
														)?.value
															? '#EFA44F'
															: 'transparent',
														color: isWrite.find(
															entry => entry.id === it.id && entry.student === selectStudent
														)?.value
															? '#FFF'
															: '#EFA44F',
													}}>
													<Icon>
														{isWrite.find(
															entry => entry.id === it.id && entry.student === selectStudent
														)?.value ? (
															<FaCheck />
														) : (
															<BsFillPencilFill />
														)}
													</Icon>
													<p>학습일지</p>
												</button>
											)}
										</td>
										<td>{it.schoolType}</td>
										<td>{convertBinaryToDays(it.days)}</td>
										<td>
											{it.time == '' || it.time == '00:00'
												? '-'
												: it?.time?.slice(3, 5) === '00'
												  ? it?.time?.slice(0, 2) + '시간'
												  : it?.time?.slice(0, 2) + '시간' + it?.time?.slice(3, 5) + '분'}
										</td>
										<td>
											{it.teacherNames?.length == 1 ? it.teacherNames : it.teacherNames.join(', ')}
										</td>
										<td>{it.type}</td>
										<td>{it?.startedAt?.slice(0, 10)}</td>
										<td>{it?.endedAt?.slice(0, 10) == '' ? '-' : it?.endedAt?.slice(0, 10)}</td>
									</tr>
								))}
							</tbody>
						</LectureViewTable>
						{isAdd ? (
							<IsAdd>
								<div className="AddHeader">
									<LectureListSearch style={{ width: '100%' }}>
										<input
											type="text"
											placeholder="강의검색"
											value={searchKeyword}
											onChange={e => setSearchKeyword(e.target.value)}
										/>
										<Icon style={{ color: '#DDAFAF', scale: '1.5' }}>
											<CiSearch />
										</Icon>
									</LectureListSearch>
									<div className="btns">
										<button className="add" onClick={clickAddStudent}>
											추가
										</button>
										<button className="cancel" onClick={() => setIsAdd(false)}>
											취소
										</button>
									</div>
								</div>
								<div className="AddTable">
									<LectureViewTable>
										<tbody>
											<tr>
												<th>강의명</th>
												<th>대상</th>
												<th>요일</th>
												<th>방식</th>
												<th>강사</th>
											</tr>
											{searchAddLecture()?.map((it, idx) => (
												<tr
													className="student"
													key={idx}
													onClick={() => setAddLecture(it.id)}
													style={{
														backgroundColor:
															AddLecture == it.id ? 'rgba(247, 139, 133, 0.5)' : 'transparent',
													}}>
													<td>{it.name}</td>
													<td>{it.schoolType}</td>
													<td>{convertBinaryToDays(it.days)}</td>
													<td>{it.type}</td>
													<td>
														{it.teacherNames?.length == 1
															? it.teacherNames
															: it.teacherNames.join(', ')}
													</td>
												</tr>
											))}
										</tbody>
									</LectureViewTable>
								</div>
							</IsAdd>
						) : (
							<IsAddBtn onClick={() => setIsAdd(true)}>
								<FaPlus />
								<p>추가</p>
							</IsAddBtn>
						)}
					</div>
				)}
			</LectureView>
			{isAddStudent && <AddStudentModal setIsAddStudent={setIsAddStudent} />}
		</LectureWrapper>
	)
}

export default React.memo(Student)
